 @import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
 @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap");
 $modalBackdrop: rgba(0, 0, 0, 0.4);
 $borderColor: rgba(0, 0, 0, 0.25);
 $blue: #2b78ff;
 $lightBlue: rgba(219, 233, 255, 0.7);
 $bgBlue: #dbe9ff8a;
 $orange: #ff772b;
 $lightOrange: #fff2ea;
 $white: #ffffff;
 $grey: #746e6e;
 $darkbg: rgba(29, 53, 87, 1);
 $tablehead: #b9d8f9;
 $tablebody: rgba(219, 233, 255, 0.25);
 $headerHeight: 65px;
 $largeMenuWidth: 300px;
 $smallMenuWidth: 49px;
 $whiteLowOpacity: rgba(255, 255, 255, 0.15);

 .marketing-css {

     ////////////////////////////////////////PLACEHOLDER CSS///////////////////////////////////////////////
     ::placeholder {
         color: $modalBackdrop !important;
         line-height: 18px !important;
     }

     ////////////////////////////////////////SCROLLBAR CSS/////////////////////////////////////////////////
     ::-webkit-scrollbar {
         width: 5px;
     }

     ::-webkit-scrollbar-track {
         background: #f1f1f1;
     }

     ::-webkit-scrollbar-thumb {
         background: $darkbg;

         &:hover {
             background: #888;
         }
     }

     ///////////////////////////////////////////// COMMON CSS /////////////////////////////////////////////
     html,
     body,
     #root,
     .full-height {
         height: 100%;
     }

     .full-width {
         width: 100%;
     }

     body {
         font-family: "DM Sans", sans-serif !important;
         font-size: 14px;
     }
     .ql-container.ql-snow {
        height:  fit-content !important;
        min-height: 100px;
      }
     select {
         optgroup {
             font-weight: 100 !important;
             color: #00000073;
             font-size: 11px;
         }

         option {
             font-size: 14px;
             color: #000;
         }
     }

     ul {
         list-style-type: none;
         margin: 0;
         padding: 0;
     }

     .w-60per {
         width: 60%;
     }

     .modal {
         display: block !important;
         background: $modalBackdrop;
     }

     .orange-head {
         color: $orange;
     }

     .blue-color {
         color: $blue;
     }

     .form-control {
         background-color: $lightBlue !important;
         border-radius: 6px;
         border: 0 !important;
         //  margin-top: 7px;
         padding: 10px;
         font-size: 14px;

         // max-width: 240px;
         // width: 100%;
         &:focus {
             outline: none !important;
             box-shadow: none;
         }

         .form-select-lg {
             line-height: 18px;
             margin-bottom: 10px;
             font-size: 11px;
         }
     }

     .form-select {
         background-color: $lightBlue !important;
         border-radius: 6px;
         border: 0 !important;
         //  margin-top: 7px;
         padding: 6.5px;
         font-size: 14px;
         width: 100%;

         // max-width: 240px;
         // width: 100%;
         &:focus {
             outline: none !important;
             box-shadow: none;
         }

         .form-select-lg {
             line-height: 18px;
             margin-bottom: 10px;
             font-size: 11px;
         }
     }

     .white-form-control,
     .white-form-select {
         background-color: white !important;
         border-radius: 6px;
         border: 0 !important;
         margin-top: 7px;
         padding: 10px;

         // max-width: 240px;
         // width: 100%;
         &:focus {
             outline: none !important;
             box-shadow: none;
         }

         .form-select-lg {
             line-height: 18px;
             margin-bottom: 10px;
             font-size: 11px;
         }
     }

     .multi-select-btn {
         max-width: none !important;
     }

     .submit {
         font-size: 14px;
         border-radius: 12px;
         background-color: $blue;
         color: $white;
         padding: 10px;
         max-width: 150px !important;
         width: 100%;

         &:hover {
             border: 1px solid $blue;
             //  color: $blue;
         }
     }

     .max-w-0 {
         max-width: none !important;
     }

     .collection-box {
         background: $white;
         box-shadow: 0px 4px 35px $borderColor;
         border-radius: 12px;
         padding: 20px;
     }

     .blue-box {
         background-color: $lightBlue;
         border-radius: 12px;
         height: 300px;

         p {
             padding: 15px 10px;
             background: $lightOrange;
             margin-top: 10px;
             border-radius: 12px;
             color: $orange;
         }

         .upload-csv {
             border: 2px dotted $modalBackdrop;
             position: relative;
             top: 35%;

             label {
                 font-family: "DM Sans", sans-serif !important;
                 cursor: pointer;
                 color: $modalBackdrop;
                 font-size: 14px !important;

                 i {
                     font-size: 30px;
                 }

                 span {
                     top: -5px;
                     position: relative;
                 }
             }

             input {
                 display: none;
             }
         }
     }

     .btn-grp-own {
         width: 100%;
         max-width: 760px;

         .btn {
             padding: 16px 5px;
             border: none !important;
             width: calc(100% / 4);
             font-size: 12px;
         }
     }

     //////////////////////////////////CARD CSS//////////////////////////////////////////////////////////
     .card {
         .card-body {
             height: 160px;
             overflow-y: scroll;

             a {
                 text-decoration: none;
                 color: #212529 !important;
             }

             p {
                 cursor: pointer;

                 &:hover {
                     color: $orange;
                 }
             }
         }

         .card-header {
             background-color: $darkbg;
             color: $white;
         }
     }

     //////////////////////////////LANDING PAGE////////////////////////////////////////////////////////
     .landing-page {
         .module-icons {
             width: 16px;
         }

         .landing-page-header {
             width: 25px;
         }
     }

     ///////////////////////////////////////////// table css /////////////////////////////////////////////
     .collection-table {
         table {
             // width: 60%;

             thead {
                 background: $tablehead;
                 text-align: left !important;

                 th {
                     padding: 20px;
                 }

                 th:first-child {
                     border-top-left-radius: 15px;
                 }

                 th:last-child {
                     border-top-right-radius: 15px;
                 }
             }

             tbody {
                 background: $tablebody;

                 td {
                     border-bottom: 0 !important;
                     padding: 20px !important;
                 }

                 tr {
                     &:first-child {
                         color: $grey;
                         font-weight: normal;
                         border-bottom: solid 1px $borderColor;
                         height: 70px !important;
                     }

                     .table-icon {
                         border: 0;
                         padding: 0;
                         background-color: transparent;

                         &:hover {
                             background-color: transparent;
                         }
                     }
                 }

                 tr:nth-last-child(1) {
                     td:first-child {
                         border-bottom-left-radius: 15px;
                     }

                     td:last-child {
                         border-bottom-right-radius: 15px;
                     }
                 }
             }
         }
     }

     .table-other {
         font-size: 12px !important;

         th {
             color: rgba(0, 0, 0, 0.65) !important;
             font-weight: normal !important;
             text-align: center;
         }

         .logs-th,
         .logs-td {
             width: 350px !important;
         }
     }

     .location-table {
         td:nth-child(3) {
             max-width: 250px !important;
             white-space: normal !important;
         }
     }

     ///////////////////////////////////////////// TAB CSS /////////////////////////////////////////////
     .rule-tab {
         h3 {
             font-weight: bold !important;
         }

         .nav-tabs {
             border-bottom: 0 !important;

             .nav-link {
                 border-top: 0 !important;
                 border-left: 0 !important;
                 border-right: 0 !important;
                 border-bottom: solid 1px $borderColor !important;
                 color: $modalBackdrop;
                 cursor: pointer;
                 text-align: center;
             }

             .active {
                 color: $blue !important;
             }
         }

         .config-body {
             padding: 30px;
             border-radius: 12px;
             background: $bgBlue;

             .form-control {
                 max-width: 240px;
                 width: 100%;
             }
         }

         .addMore {
             color: $blue;
         }

         .remove {
             color: $orange;
             background-color: $lightOrange !important;
         }
     }

     ///////////////////////////////////////////// LOGIN CSS /////////////////////////////////////////////
     .collection-login {
         .reset-pass {
             border-right: 1px solid $borderColor !important;
         }

         input {
             margin-top: 0 !important;
             padding: 15px 10px;
             border: 1px solid $borderColor !important;
             border-right: 0 !important;
             background: $white !important;
         }

         .input-group {
             width: 100%;

             .input-group-text {
                 border: 1px solid $borderColor !important;
                 background-color: $white;

                 .bi-eye,
                 .bi-eye-slash {
                     &:hover {
                         color: $blue;
                     }
                 }
             }
         }

         .btn {
             padding: 10px;
         }
     }

     .form-label {
         font-family: "DM Sans", sans-serif !important;
         font-weight: 400;

     }

     .link-input {
         background: url('./maketingimages/linkimage.png') no-repeat scroll 7px 7px;
         background-size: 15px;
         background-position-y: center;
         background-position: 95%;
     }

     .image-upload-div {
         border: 1px dashed #2B78FF;
         background-size: 29%;
         background-position: center;
         background-repeat: no-repeat;
         position: relative;
         height: 50px;
         margin-left: auto;
         margin-right: auto;
     }

     .upload-main {
         border-radius: 6px;
         background: var(--text-box-color, rgba(219, 233, 255, 0.70));
         padding: 31px 53px;
     }

     .drag-text-new {
         //  padding: 3px;
         color: #2B78FF;
         font-family: DM Sans;
         font-size: 14px;
         font-style: normal;
         font-weight: 400;
         line-height: normal;
         text-align: center;
         margin-top: 15px;
     }

     .upload-icon {
         background: url('./maketingimages/upload.png') no-repeat scroll 7px 7px;
         background-size: 20px;
         background-position-y: center;
         background-position: 15%;
     }

     .phone-input {
         background: url('./maketingimages/telephone-fill.png') no-repeat scroll 7px 7px;
         background-size: 13px;
         background-position-y: center;
         background-position: 95%;
     }

     .email-input {
         background: url('./maketingimages/envelope-fill.png') no-repeat scroll 7px 7px;
         background-size: 15px;
         background-position-y: center;
         background-position: 95%;
     }

     .rupee-input {
         background: url('./maketingimages/currency-rupee.png') no-repeat scroll 7px 7px;
         background-size: 15px;
         background-position-y: center;
         background-position: 95%;
     }



     .PhoneInputInput {
         width: 86%;
         margin-left: 41px;
         //  margin-top: -19px !important;
         position: absolute;
         background: transparent;
     }

     .PhoneInputCountrySelect {
         width: 30px !important;
     }
 }

 .custom-checkbox {
     .checkbox-wrapper-12 {
         position: relative;
     }

     .checkbox-wrapper-12>svg {
         position: absolute;
         top: -130%;
         left: -170%;
         width: 110px;
         pointer-events: none;
     }

     .checkbox-wrapper-12 * {
         box-sizing: border-box;
     }

     .checkbox-wrapper-12 input[type="checkbox"] {
         -webkit-appearance: none;
         -moz-appearance: none;
         appearance: none;
         -webkit-tap-highlight-color: transparent;
         cursor: pointer;
         margin: 0;
     }

     .checkbox-wrapper-12 input[type="checkbox"]:focus {
         outline: 0;
     }

     .checkbox-wrapper-12 .cbx {
         width: 24px;
         height: 24px;
         top: calc(50vh - 12px);
         left: calc(50vw - 12px);
     }

     .checkbox-wrapper-12 .cbx input {
         position: absolute;
         top: 0;
         left: 0;
         width: 24px;
         height: 24px;
         border: 2px solid #bfbfc0;
         border-radius: 50%;
     }

     .checkbox-wrapper-12 .cbx label {
         width: 24px;
         height: 24px;
         background: none;
         border-radius: 50%;
         position: absolute;
         top: 0;
         left: 0;
         -webkit-filter: url("#goo-12");
         filter: url("#goo-12");
         transform: trasnlate3d(0, 0, 0);
         pointer-events: none;
     }

     .checkbox-wrapper-12 .cbx svg {
         position: absolute;
         top: 5px;
         left: 4px;
         z-index: 1;
         pointer-events: none;
     }

     .checkbox-wrapper-12 .cbx svg path {
         stroke: #fff;
         stroke-width: 3;
         stroke-linecap: round;
         stroke-linejoin: round;
         stroke-dasharray: 19;
         stroke-dashoffset: 19;
         transition: stroke-dashoffset 0.3s ease;
         transition-delay: 0.2s;
     }

     .checkbox-wrapper-12 .cbx input:checked+label {
         animation: splash-12 0.6s ease forwards;
     }

     .checkbox-wrapper-12 .cbx input:checked+label+svg path {
         stroke-dashoffset: 0;
     }

     @-moz-keyframes splash-12 {
         40% {
             background: #006fbc;
             box-shadow: 0 -18px 0 -8px #006fbc, 16px -8px 0 -8px #006fbc, 16px 8px 0 -8px #006fbc, 0 18px 0 -8px #006fbc, -16px 8px 0 -8px #006fbc, -16px -8px 0 -8px #006fbc;
         }

         100% {
             background: #006fbc;
             box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
         }
     }

     @-webkit-keyframes splash-12 {
         40% {
             background: #006fbc;
             box-shadow: 0 -18px 0 -8px #006fbc, 16px -8px 0 -8px #006fbc, 16px 8px 0 -8px #006fbc, 0 18px 0 -8px #006fbc, -16px 8px 0 -8px #006fbc, -16px -8px 0 -8px #006fbc;
         }

         100% {
             background: #006fbc;
             box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
         }
     }

     @-o-keyframes splash-12 {
         40% {
             background: #006fbc;
             box-shadow: 0 -18px 0 -8px #006fbc, 16px -8px 0 -8px #006fbc, 16px 8px 0 -8px #006fbc, 0 18px 0 -8px #006fbc, -16px 8px 0 -8px #006fbc, -16px -8px 0 -8px #006fbc;
         }

         100% {
             background: #006fbc;
             box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
         }
     }

     @keyframes splash-12 {
         40% {
             background: #006fbc;
             box-shadow: 0 -18px 0 -8px #006fbc, 16px -8px 0 -8px #006fbc, 16px 8px 0 -8px #006fbc, 0 18px 0 -8px #006fbc, -16px 8px 0 -8px #006fbc, -16px -8px 0 -8px #006fbc;
         }

         100% {
             background: #006fbc;
             box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
         }
     }

 }